import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Gioco1Component } from './pages/gioco1/gioco1.component';
import { Gioco2Component } from './pages/gioco2/gioco2.component';
import { CartaComponent } from './components/carta/carta.component';

@NgModule({
  declarations: [
    AppComponent,
    Gioco1Component,
    Gioco2Component,
    CartaComponent
  ],
  imports: [
    BrowserModule,
    Angulartics2Module.forRoot(),
    AppRoutingModule
  ],
  providers: [
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

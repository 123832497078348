import { Component, OnInit } from '@angular/core';


export interface CartaGioco {
  description: string;
  index: number;
  carta: string;
  name: string;
}



const carte: CartaGioco[] = [

  {
    index: 1,
    description: 'Detto anche "L\'innamorato", è una carta che rappresenta l\’amore, l\'armonia, le affinità, la scelta e i sentimenti reciproci. Il desiderio di una relazione d\’amore, l\’attrazione fisica. Gli amanti rappresentano anche le unioni forti, l\'equilibrio tra corpo e mente, tra l\'essenza femminile e quella maschile. Questa armonia, questa reciprocità può manifestarsi in tutti gli ambiti della vita: l\'amicizia, il lavoro, la famiglia o qualsiasi situazione in cui due forze vive si uniscono per perseguire e realizzare lo stesso obiettivo. Capovolta: Rappresenta un amore oppressivo, separazione e rottura, infedeltà, incompatibilità, contrasti ed incomprensioni.',
    carta: '/assets/carte/gliaamanti.jpg',
    name: 'Gli Amanti',
  },
  {
    index: 2,
    description: 'Detto anche "Il Mago", è una carta positiva, rappresenta l\'innovazione, i risultati e la giovinezza. Rappresenta anche una forte intelligenza con importanti capacità creative. Una persona che ha piena padronanza di sé. Indica l’inizio di una nuova azione, un\’impresa, un cambiamento e consiglia di perseguire qualunque tipo di progetto. In Amore, indica l\’inizio di un nuovo rapporto sentimentale, mentre sul lavoro, nuove possibilità allettanti. Capovolta: Rappresenta un\’azione volta al male, sfiducia verso se stessi. Ossessione per la parte materiale della vita.',
    carta: '/assets/carte/ilbagatto.jpg',
    name: 'Il Bagatto',
  },
  {
    index: 3,
    description: 'E\' una carta dai tratti piuttosto positivi, rappresenta il successo netto e forte su un determinato quesito che porterà alla piena soddisfazione. Autocontrollo. Predizione di un viaggio. In amore indica che è arrivato il momento giusto per cercare di costruire qualcosa di stabile con il partner, una convivenza o un matrimonio, oppure un riavvicinamento. In ambito lavorativo, indica una situazione invidiabile, caratterizzata da successi, promozioni e occasioni favorevoli. Capovolta: Rappresenta  il vanto eccessivo del proprio successo che diverrà calamita per antipatie e invidie. Può anche suggerire un atteggiamento presuntuoso e altezzoso. ',
    carta: '/assets/carte/ilcarro.jpg',
    name: 'Il Carro',
  },
  {
    index: 4,
    description: 'Rappresenta il male, un pericolo imminente. E\’ la carta più negativa dei Tarocchi e porta con se la degenerazione, ogni sorta di vizio. Schiavitù, dipendenza, ossessione, lussuria. Può simboleggiare un pericolo. Può indicare anche il raggiungimento del successo, ottenuto però con metodi poco ortodossi e corretti. Capovolta: Simboleggia il male puro. Grossi ostacoli e insidie sbarrano la strada al successo. Sul piano affettivo può indicare una gravidanza indesiderata. Insuccessi e critiche pesanti caratterizzano invece l\’aspetto lavorativo. In alcuni casi può indicare la fine di una situazione di pericolo o negativa.',
    carta: '/assets/carte/ildiavolo.jpg',
    name: 'Il Diavolo',
  },
  {
    index: 5,
    description: 'Qualcosa sta cambiando, è un segno di rinnovamento. Rinascere, ricominciare una nuova vita, uscire dalla routine. Il comportamento è più maturo e spirituale. La situazione cambia totalmente, è arrivato il momento della resa dei conti. Niente più temporeggiamenti, né rinvii, è il tempo di affrontare la realtà e le conseguenze di questi cambiamenti. Indica la necessità di considerare la vita da un punto di vista meno materialistico e ritrovare quei valori che rendono davvero felici. Il significato di questa carta vale sia in ambito sentimentale che professionale, in cui si prospetta una vera evoluzione. Capovolta: Rappresenta una punizione subita, una resa dei conti sfavorevole. Azioni negative, comportamenti scorretti che vengono scoperti e sanzionati.',
    carta: '/assets/carte/ilgiudizio.jpg',
    name: 'Il Giudizio',
  },
  {
    index: 6,
    description: 'La carta del Matto può avere un duplice significato: sia positivo che negativo. Il significato positivo è l\'entusiasmo, l\’incitazione a lasciarsi andare, vivere liberamente e  a fare ciò che più amiamo rompendo con la quotidianità. Indica estro e genialità (spesso incompresa). Nella sua accezione negativa, invece, rimanda ad azioni sconsiderate, «da matto» appunto, un bisogno di ritornare sui propri passi o poca stima di sé stessi. Capovolta: sfocia nell\’arroganza e nell\’aggressività gratuita, oltre ogni limite. Se capovolto e unito ad Arcani deboli, indica una situazione meno catastrofica ma pur sempre negativa, fatta di insicurezze e depressione. Rappresenta l\’agire con incoscienza, senza cognizione di causa. Irresponsabilità, irrazionalità, ossessione, immaturità, mancanza di logica, mancanza di valori guida.',
    carta: '/assets/carte/ilmatto.jpg',
    name: 'Il Matto',
  },
  {
    index: 7,
    description: 'Questo Arcano ha un significato piuttosto positivo, anche nella sua accezione negativa. Per questo, insieme al Sole, è la migliore carta del mazzo. Nel caso in cui esca diritto, il Mondo è sinonimo di perfezione, completamento. Rappresenta la realizzazione, un successo in arrivo, la prosperità, la gioia. Ogni obiettivo verrà raggiunto provando profonda soddisfazione, senso di benessere e serenità interiore. In amore indica un fidanzamento imminente, un matrimonio o una gravidanza desiderata. Sul piano lavorativo, indica un periodo di crescita e realizzazione personale. Capovolta: Al di là di un comportamento scorretto avremo comunque il successo. Verranno tenuti comportamenti sbagliati, ma nonostante questo tutto andrà bene. Preannuncia un sacrificio, così come alcune incomprensioni con il partner e periodi di stallo in ambito lavorativo.',
    carta: '/assets/carte/ilmondo.jpg',
    name: 'Il Mondo',
  },
  {
    index: 8,
    description: 'Rappresenta una figura paterna, un buon consiglio, una persona leale su cui contare e dalla quale è bene imparare. Descrive una persona fedele e leale, un mentore una persona da cui tutti dovrebbero prendere esempio. L\’Arcano del Papa ripudia il materialismo e la ricerca continua dell\’utile e del profitto. Capovolta: Rappresenta la ricerca dell’approvazione altrui, il seguire la massa senza avere un ideale proprio. Descrive una mancanza di forza o di coraggio e poca convinzione nel difendere le proprie opinioni.',
    carta: '/assets/carte/ilpapa.jpg',
    name: 'Il Papa',
  },
  {
    index: 9,
    description: ' È una delle carte più fortunate e rassicuranti del mazzo. Soprattutto quando la carta esce diritta: i suoi significati sono positivi e promettenti. Rappresenta il massimo che si può desiderare, il pieno appagamento e il superamento di tutti gli ostacoli che la vita ti pone di fronte. Tutto è armonia poiché la luce del Sole spazza via i pericoli che si nascondono nelle tenebre. Tutto procederà nel migliore dei modi. I principali significati si possono riassumere in: visione chiara della situazione, fortuna, successo, talento, equilibrio, sicurezza, vittoria, energia, guadagni. In amore può indicare un incontro esaltante destinato ad essere duraturo. Capovolta: Rappresenta un ritardo che una precisa situazione subisce, prima dell’esito positivo. Si fatica nel trarre gioia e spensieratezza dalle situazioni che sono positive. La generosità diventa egoismo, la gioia pessimismo.',
    carta: '/assets/carte/ilsole.jpg',
    name: 'Il Sole',
  },
  {
    index: 10,
    description: 'Questa carta ha un significato molto positivo. Rappresenta la risoluzione di un problema grazie alla propria forza interiore, alla propria intelligenza, al coraggio e all\’astuzia. Simboleggia potenza e autocontrollo. Esprime forte personalità, influenza, altruismo. In Amore è simbolo di passione, fedeltà e sincerità del partner. Se ci sono delle difficoltà nel rapporto vengono affrontate con forza e determinazione. A livello sessuale esprime vigore e vivacità. Sul lavoro indica il successo e desideri esauditi.  Capovolta: Rappresenta un senso di debolezza o di violenza. Un risultato negativo dovuto all\'impulsività o all\’uso della forza. ',
    carta: '/assets/carte/laforza.jpg',
    name: 'La Forza',
  },
  {
    index: 11,
    description: 'In posizione verticale o se accompagnata da altre carte positive, indica integrità, onestà, ragione ed equilibrio. Rappresenta una prova superata, l\’equità del giudicare o nel ricevere un giudizio. In Amore, può indicare un matrimonio o la tanto attesa fine di un procedimento di divorzio. Dopo un periodo più complicato, può invece indicare armonia ed equilibrio. In ambito lavorativo, indica successi, anche nello studio. Favorisce soprattutto le professioni legate alla creatività e all\’arte. Capovolta: Rappresenta l\’ingiustizia personale o ricevuta. Processi lunghi e complessi, litigi, in presenza di carte particolarmente negative, anche reclusione.',
    carta: '/assets/carte/lagiustizia.jpg',
    name: 'La Giustizia',
  },
  {
    index: 12,
    description: 'L\’iconografia della carta rimanda chiaramente al suo significato: c’è un\’atmosfera sinistra e oscura che è portatrice di inganno. Esorta ad una maggiore attenzione. Circostante sfavorevoli, amici- nemici, persone ingannevoli, insidie nascoste. La carta indica preoccupazione, situazioni stagnanti e impossibilità o incapacità di uscirne. Per quanto riguarda gli affetti, significa amore passionale, ma anche casa e famiglia. In ambito lavorativo suggerisce di non lasciarsi scoraggiare dai pericoli né dalle difficoltà. Capovolta: Rappresenta la conferma di un grave inganno e difficoltà importanti in arrivo. Inquietudine, depressione, emozioni distruttive.',
    carta: '/assets/carte/laluna.jpg',
    name: 'La Luna',
  },
  {
    index: 13,
    description: 'Rappresenta il cambiamento. La fine di un periodo negativo con la conseguente apertura di una nuova pagina della nostra vita. Il miglior consiglio è, quindi, quello di abbracciarne le conseguenze. La morte è vista però come una figura paradossalmente giusta ed equa, poiché colpisce tutti, indistintamente dalle classi sociali. In situazioni favorevoli, può indicare un allontanamento dal passato, la fine di un ciclo negativo. Nuovi progetti, nuove idee, nuovi amori, naturalmente con un prezzo da pagare. Capovolta: Rappresenta il cattivo epilogo di una situazione, sventure. Il cambiamento è particolarmente doloroso, tantissimi sentimenti negativi, dispiacere, disperazione, angoscia, mancanza di autostima e pessimismo.',
    carta: '/assets/carte/lamorte.jpg',
    name: 'La Morte',
  },
  {
    index: 14,
    description: ' Questa carta rappresenta tutto ciò che lentamente si trasforma, seguendo un lungo processo mutativo. Rappresenta la conoscenza e i valori di rettitudine morale. Espressione della sincerità, purezza e pazienza. Allude spesso ad una figura femminile, in posizione di forza, non disposta a sottomettersi. Dal punto di vista lavorativo, non dà indicazioni particolari, se non di attendere e programmare bene le scelte. Capovolta: Rappresenta una donna intelligente ma volta al male e indica arroganza. Può indicare anche comportamenti saccenti e presuntuosi.',
    carta: '/assets/carte/lapapesse.jpg',
    name: 'La Papessa',
  },
  {
    index: 15,
    description: 'La carta simboleggia sacrificio, una condizione sfavorevole da sopportare, per raggiungere un obiettivo. Sono necessari degli sforzi importanti, delle rinunce, sarà necessario superare delle prove difficili. Rappresenta la consapevolezza che la situazione negativa è solo di passaggio nella nostra vita. Suggerisce inoltre che ritardi e ostacoli debbano essere interpretati come dei segnali del destino. Capovolta: Rappresenta una situazione complessa, preoccupante, una difficoltà lunga a passare. Un sacrificio inutile per modificare gli eventi.  Annuncia sconfitte e affaticamento. Da un punto di vista lavorativo, clima teso e bisogno di cambiare aria.',
    carta: '/assets/carte/lappeso.jpg',
    name: "L\'appeso",
  },
  {
    index: 16,
    description: 'Il significato di questa carta è senza alcun dubbio positivo, se compare in posizione verticale. Significa armonia, fortuna. Indica una situazione particolarmente favorevole per il consultante, come se le Stelle raffigurate ci proteggessero e ci aiutassero, illuminandoci la via da seguire. Rappresenta la pace interiore, il destino, la purificazione, la fertilità. Suggerisce di non porsi limiti e di puntare in alto, di rilassarsi e lasciarsi andare, di non accontentarsi mai e inseguire i propri sogni. Evoca la possibilità di un nuovo amore , più passionale che romantico. In ambito professionale, indica ottime possibilità di riuscita dei propri progetti. Capovolta: Rappresenta una buona occasione sfuggita o uno stato d\’animo negativo quando in realtà non si ha motivo per averlo. Comportamento contradditorio, eccessivo e pigro.',
    carta: '/assets/carte/lastella.jpg',
    name: 'La Stella',
  },
  {
    index: 17,
    description: 'Moderazione, protezione, purificazione ed armonia. Un equilibrio interiore da mantenere nel tempo. Un invito a rilassarsi. La Temperanza ci insegna a evitare la forza e privilegiare la dolcezza. Solitamente, fa riferimento ad un problema e alla necessità di risolverlo con pazienza e dedizione. Può preannunciare una nascita, un nuovo progetto futuro a lungo termine. Capovolta: Tutti gli effetti positivi perdono la loro forza: l’armonia diventa disordine, l’equilibrio instabilità. Un atteggiamento sbagliato in merito ad una precisa situazione, che se non corretto porterà ad un evoluzione negativa. Mancanza di moderazione, eccessi.',
    carta: '/assets/carte/latemperanza.jpg',
    name: 'La Temperanza',
  },
  {
    index: 18,
    description: 'Rappresenta una rottura, un forte trauma, la superbia punita per la mancanza di umiltà, lo sgretolamento. Preannuncia un cambiamento improvviso, una crisi, una situazione a rischio. È una torre che sta crollando dopo essere stata colpita da un fulmine. Nonostante questa carta possa assumere significati più negativi che positivi, descrive anche la voglia, la volontà e il coraggio di andare avanti. Spesso, in ambito professionale, viene consigliato di diffidare della propria cerchia e di rivedere ogni progetto, in quanto è molto forte la possibilità di fallimento. Capovolta: Indica un forte eccesso di superbia, ostentazione. Rappresenta una marcatura ancora più profonda e negativa come l\’avvicinarsi di qualcuno che è pronto a farci cadere. Perdite. ',
    carta: '/assets/carte/latorre.jpg',
    name: 'La Torre',
  },
  {
    index: 19,
    description: 'Rappresenta la saggezza nelle valutazioni, la ricerca della verità, una profonda ma lenta evoluzione. Simboleggia la prudenza, la pazienza, il senso del dovere, la concentrazione e la serenità. L\’eremita in posizione diritta assicura buoni consigli, chiarimenti e protezione. In una coppia, indica amore puro e profondo, mentre sul lavoro, risultati non immediati, ma positivi. Capovolta: Rappresenta la solitudine, tristezza, pessimismo, l\’eccessiva prudenza che porta a poche e non fruttuose azioni. Dubbio e diffidenza verso gli altri. Paura di coinvolgimento in amore. Lavoro pesante e malpagato.',
    carta: '/assets/carte/leremita.jpg',
    name: "L\'Eremita",
  },
  {
    index: 20,
    description: 'E’ una carta molto positiva. Rappresenta protezione, forza interiore e stabilità. Indica successo, riuscita, un rapporto che si sviluppa in positivo o un problema che si risolve. È la carta della fecondità e della procreazione, rappresenta una moglie fedele, una buona amica, per le donne e per gli uomini, rappresenta un rapporto leale con gli altri, soprattutto con le donne. E\’ una persona che ci sarà di aiuto e indica anche prosperità e abbondanza. Sul Lavoro, successi e promozioni assicurati a chi si impegna con intelligenza nei propri progetti. Capovolta: Rappresenta una persona molto conservatrice e dalla morale molto rigida. Esprime arroganza, immaturità e falsità',
    carta: '/assets/carte/limperatrice.jpg',
    name: "L\'Imperatrice",
  },
  {
    index: 21,
    description: 'Rappresenta l\’autorità, la forza, il potere terreno, la ricchezza, la stabilità e il carisma. Una figura prontamente saggia e giusta. Si riferisce, quindi, a tutto ciò che è solido e destinato a durare. Suggerisce una situazione positiva, da affrontare con ottimismo. In una coppia, può indicare il consolidamento finalizzato alla convivenza o al matrimonio. In ambito professionale, l\’imperatore rimanda ad iniziative che hanno un buon esito: promozioni o nuovo contratto.  Capovolta: Rappresenta l\’uso eccessivo della forza, un animo molto duro accompagnato dalla prepotenza. Il potere si trasforma in debolezza e in mancanza di motivazione.',
    carta: '/assets/carte/limperatore.jpg',
    name: "L\'Imperatore",
  },
  {
    index: 22,
    description: ' Rappresenta il cambiamento positivo, un rinnovamento interiore e materiale. Indica momenti felici, sorprese, novità positive, ma ricorda che tutto può cambiare, il movimento della Ruota è inarrestabile e imprevedibile. Sul piano sentimentale, indica l\’incontro della persona giusta per chi è ancora single, e il consolidamento per chi ha già un partner. La ruota della fortuna in amore invita a vivere il momento presente con gioia e vitalità, nella consapevolezza che il momento iliaco non sarà eterno.  A Lavoro successo, promozioni e nuovi progetti. Capovolta: Rappresenta un cambiamento negativo. Può indicare crisi, instabilità economica, precarietà, fallimento.',
    carta: '/assets/carte/ruotadellafortuna.jpg',
    name: "Ruota Della Fortuna",
  },
]


@Component({
  selector: 'app-carta',
  templateUrl: './carta.component.html',
  styleUrls: ['./carta.component.scss']
})
export class CartaComponent implements OnInit {

  cartePlaceholder: any[] = [
    { show: true },
    { show: true },
    { show: true },
    { show: true },
    { show: true },
    { show: true },
    { show: true },
    { show: true },
    { show: true },
    { show: true }
  ]


  constructor() { }

  primeTreCarte = false;
  mostraSelezioneCarte = true;

  carteEstratte: CartaGioco[] = [];

  numeroCarteDaEstrarre = 6;
  mostraAnimazione = false;
  dissolviAnimazione = false;
  mostraDescrizioneUno = true;
  mostraDescrizioneDue = true;
  mostraSignificatoCarte = false;

  fadeOut = false;

  bottonePost = false;

  showResult = false;

  ngOnInit(): void {

  }

  hideOnClick(a) {

    if (a.show == false){
      return
    }

    const that = this;
    a.show = false;
    this.fadeOut = true;
    this.estraiCarte();



    this.mostraDescrizioneUno = true;

    if (this.carteEstratte.length === 3) {
      this.primeTreCarte = true;


      this.cartePlaceholder.forEach(e => e.show = true)

      this.mostraDescrizioneDue = false;

    }
    this.mostraDescrizioneUno = false;
    if (this.carteEstratte.length === 6) {

      this.mostraDescrizioneDue = true;

      this.mostraSelezioneCarte = false;
      this.mostraAnimazione = true;

      setTimeout(function () {
        that.dissolviAnimazione = true;
      }, 4000)

      setTimeout( function () {
        that.mostraAnimazione = false;
        that.bottonePost = true;
      }, 5000)


    }

  }
  mostraRisultato() {
    this.mostraSignificatoCarte = true;
  }

  estraiCarte() {
    while (this.carteEstratte.length < this.numeroCarteDaEstrarre) {
      const randomNumber = this.getRandomNumberBetween(1, carte.length)
      const cartaGiaEstratta = this.carteEstratte.find(carta => carta.index == randomNumber)
      if (cartaGiaEstratta == undefined) {
        this.carteEstratte.push(carte[randomNumber - 1])
        break
      }
    }

  }

  getRandomNumberBetween(min, max) {
    return Math.floor(Math.random() * max) + min;
  }


}

<div class="row">
    <div class="col-lg-12 text-center">
        <h2 class="mt-4">Vedi cosa dicono le carte sul tuo futuro in modo semplice e veloce!</h2>
    </div>
</div>
<div style="display: flex; flex-direction: row;">
    <div>
        <a href="javascript:void(0)" (click)="estraiCarte()" (click)="clickCount()" class="btn btn-class btn-back"></a>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div *ngFor="let e of carteEstratte" class="col-lg-3 mt-2 mb-2" style="display: flex; flex-direction: row;">
                <div class="card card-custom my-auto" style="width: 100%; align-items: center;">
                    <img src="{{e.carta}}" class="img-fluid custom-img animated rollIn delay-1s">
                </div>

            </div>
        </div>
    </div>
</div>
<!---->

<div class="container">
    <div class="row">
        <div class="col-lg-12 text-center">
            <button class="btn btn-class btn-custom" (click)="showMeanings()" data-toggle="modal"
                data-target="#exampleModal">Cosa Significano?</button>
        </div>
    </div>
</div>

<div  class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" style="min-width: 800px;" role="document">
        <div class="modal-content" style=" padding: 1rem; font-size: 14px;">
            <div class="modal-header" style="margin: auto;">
                <h5 class="modal-title" id="exampleModalLabel">Significati</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngFor="let e of carteEstratte">
                <div class="row">
                    <div class="col-lg-3">
                        <ul style="list-style-type: none; margin-right: 0px;">
                            <li>
                                <img src="{{e.carta}}" class="img-fluid img-inside animated fadeIn">
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-9">
                        <p>
                            {{e.description}}
                        </p>
                        <hr style="margin: 0px;">
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { environment } from 'src/environments/environment';

declare function setupAnaliticsProd(): any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cartomanzia-daniel';

  constructor(private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    @Inject(PLATFORM_ID) private platformID: any) {
    if (isPlatformBrowser(this.platformID)) {
      if (environment.production) {
        angulartics2GoogleAnalytics.startTracking();
        setupAnaliticsProd();
      }
    }
  }

}

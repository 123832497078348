<div class="container-fluid text-center mt-2" style="min-height: 40vh;">
    <h2>TAROCCHI ONLINE: Consulto Gratuito!</h2>

    <div class="container custom-cont" *ngIf="mostraSelezioneCarte == true">
        <div (click)="hideOnClick(a)" class="card card-custom animated fadeIn" [ngClass]="{ 'fadeOut fast' : a.show == false }" *ngFor="let a of cartePlaceholder">
            <img src="/assets/carte/cartaretro.jpg" class="img-fluid custom-img" alt="">
        </div>
    </div>

    <div *ngIf="mostraDescrizioneUno == true">
        <h4>Seleziona le prime 3 carte pensando ad una tua Qualità!</h4>
    </div>
    <div *ngIf="mostraDescrizioneDue == false">
        <h4>Seleziona altre 3 carte pensando ad un tuo Difetto!</h4>
    </div>

    <div *ngIf="mostraAnimazione == true" [ngClass]="{ 'animated fadeOut fast' : dissolviAnimazione == true }">
        <div class="row">
            <div class="col-lg-12 text-center animated fadeIn">
                <img id="loading" src="/assets/carte/stellarot.svg" class="img-fluid custom-anim mt-4" alt="newStart">
                <br>
                <p class="stilep animated pulse infinite ">Attendi qualche secondo...</p>
            </div>
        </div>
    </div>
    <div class="m-2 animated fadeIn" style="margin-top: 4rem!important;" *ngIf="bottonePost === true">
        <button (click)="mostraRisultato()" class="btn btn-custom btn-lg">SCOPRI IL SIGNIFICATO</button>
    </div>
</div>

<div class="container-fluid animated fadeIn">
    <div class="container contenitore-risultato" *ngIf="mostraSignificatoCarte == true">

        <div class="row">
            <div class="col-lg-12 text-center">
                <h4>Ecco il risultato, Controlla cosa dicono le carte sul tuo futuro!</h4>
            </div>
            <div class="col-lg-12 animated fadeIn delay-1s" *ngFor="let a of carteEstratte">
                <div class="card card-custom-2">
                    <div style="display: flex; flex-direction: row;">
                        <img src="{{a.carta}}" class="img-fluid my-auto img-style-2" alt="tarocco">
                        <div class="desc-name" style="display: flex; flex-direction: column;">
                            <h3 style="margin:auto;">{{a.name}}</h3>
                            <p>{{a.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<header>
  <nav class="navbar navbar-expand-lg bg-custom fixed-top">


    <!--DIV1-->

    <div class="logo-mobile">
      <a href="http://www.cartomanziadaniel.com/" class="logo"><img src="/assets/logo-300x53.png" class="img-fluid"></a>
    </div>
    <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#nav">
      <span class="navbar-toggler-icon"><i class="fa fa-bars" style="margin-top: 6px; color: white;"></i></span>
    </button>
    <div class="filler"></div>



    <!--DIV2-->
    <div class="navbar-collapse-anchor">
      <div class="navbar-collapse-wrapper">
        <div class="collapse navbar-collapse navbar-collapse-custom" id="nav">
          <ul class="navbar-nav animated fadeInLeft bn1">
            <li>
              <a href="#home" class="nav-link line">HOME</a>
            </li>
            <li>
              <a href="#cartomanziainamore" class="nav-link line">CARTOMANZIA IN AMORE</a>
            </li>
            <li>
              <a href="#tarocchidegliangeli" class="nav-link line">TAROCCHI DEGLI ANGELI</a>
            </li>
            <li>
              <a href="#chisono" class="nav-link line">CHI SONO</a>
            </li>
            <li>
              <a angulartics2On="click" angularticsAction="Chiamata"
              [angularticsCategory]="'Conversione'" href="tel:899775097" style="font-weight: 500;" class="nav-link line"><i class="fa fa-phone"></i>
                899.775.097</a>
            </li>
          </ul>
        </div>
      </div>
    </div>





  </nav>
  <nav class="navbar nav-custom"></nav>
</header>

<div class="container-fluid con-bg-2 sfondo" style="min-height: 600px;">
  <div class="row">
    <div class="col-lg-6 col-nopadding">
      <div class="headh">
      <h3 style="margin-top: 1rem; font-weight: 300;">Senti di aver bisogno di risposte?
        <span style="font-weight: 700;">Ti manca la chiarezza </span>per fare le scelte giuste</h3>
      <h3 class="init-h3" style="font-size: 38px; margin-top: 1rem;font-family: Montserrat, Sans-serif;"><strong>Poni fine ai tuoi dubbi
          con una guida concreta che sa darti le risposte che MERITI di avere.</strong></h3>
      <h3 style="margin-top: 1rem; font-weight: 300;">
        Con <strong style="font-weight: 500;">Daniel e le sue Cartomanti</strong> in ogni circostanza possiamo aiutarti a:
      </h3>
</div>
      <ul style="list-style-type: none; margin-left: -40px;">
        <li>
          <p class="vantaggi"><i class="fa fa-check" style="color:green"></i>
            Gestire in pochi
            minuti i tuoi dubbi e problemi</p>
        </li>
        <li>
          <p class="vantaggi"><i class="fa fa-check" style="color: green;"></i>
            Ottenere risposte
            concrete</p>
        </li>
        <li>
          <p class="vantaggi"><i class="fa fa-check" style="color: green;"></i>
            Creare un
            percorso
            mirato alla rinascita della tua persona</p>
        </li>
      </ul>

      <a angulartics2On="click" angularticsAction="Chiamata"
      [angularticsCategory]="'Conversione'" href="tel:899775097" style="text-decoration: none;"
        class="head-899 btn btn-outline bg-custom bg-header-custom"><i class="fa fa-phone"></i> Scegli Daniel e le sue Cartomanti</a>
      <p style="color: white;">Chiama a soli <strong>1,00 €/min</strong> + iva!</p>
    </div>
    <div class="col-lg-6">
      <img src="/assets/daniel-nuovo.png" alt="daniel cartomante" class="img-fluid header-img">
    </div>
  </div>
</div>


<div class="container-fluid" style="margin-top: 1rem;padding: 1rem; background-color:#33428c;">
  <div class="container displayed">
    <div class="row text-center">
      <div class="col-lg-4">
        <div class="banner bn1 animated zoomIn delay-1s">
          <div class="img-card">
            <a angulartics2On="click" angularticsAction="Chiamata"
            [angularticsCategory]="'Conversione'" href="tel:899775097" style="text-decoration: none;">
              <img src="/assets/cartomanzia-con-899.jpg" class="img-fluid fluid1">
            </a>
          </div>

        </div>
      </div>
      <div class="col-lg-4">
        <div class="banner bn2 animated zoomIn delay-1s">
          <div class="img-card">
            <a angulartics2On="click" angularticsAction="Chiamata"
            [angularticsCategory]="'Conversione'" href="tel:0695541670" style="text-decoration: none;">
              <img src="/assets/cartomanziaconcc-new.jpg" class="img-fluid fluid1">
            </a>
          </div>

        </div>
      </div>
      <div class="col-lg-4">
        <div class="banner bn3 animated zoomIn delay-1s">
          <div class="img-card">
            <a href="https://customers.b4tlc.it/application/B4tlc/index.php?r=pr_cc/CCrecharge4&ddi=0695545663" angulartics2On="click" angularticsAction="Chiamata"
            [angularticsCategory]="'Conversione'" style="text-decoration: none;">
              <img src="/assets/ricaricaconpaypal-new.jpg" class="img-fluid fluid1">
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!--
<div class="container-fluid" style="margin-top: 1rem; background-color:#33428c;">
  <div class="row">
    <div class="col-lg-12 text-center">
      <a href="tel:0695545663"> <img src="/assets/cartomanzia-paypal.jpg" class="img-fluid" style="padding: 1rem;"></a>
    </div>
  </div>
</div>
-->
<hr style="background-color: #33428c; height: 3px; margin-left: 35%; margin-right: 35%;">
<div class="container" id="cartomanziainamore" style="margin-top: 1rem;margin-bottom: 1rem;">
  <div class="row text-center">
    <div class="col-lg-12">
      <h3 style="font-weight: 500; font-size: 40px; color: black;">Le Migliori Cartomanti in Amore al Telefono
        per Risolvere tutti i tuoi Dubbi</h3>
    </div>
    <div class="col-lg-12">
      <p style="color:black; font-size: 18px; font-weight: 400;">Benvenuta nel regno di Daniel e le sue Cartomanti. Qui
        non troverai un Centro
        di Cartomanzia
        Online qualunque.
        I nostri Consulti non mirano a rivelarti passivamente il tuo futuro, il nostro obiettivo è lavorare sul tuo
        Presente per migliorare la tua persona e darti gli strumenti per affrontare positivamente il domani.</p>
      <p style="color:black; font-size: 18px; font-weight: 400;">Le nostre Cartomanti al Telefono sono Sensitive
        <strong>SELEZIONATISSIME e SCELTISSIME</strong>
        secondo un Codice Etico e Deontologico che io stesso, Daniel Missori, ho ideato e che seguo scrupolosamente per
        darti – non uno qualsiasi – bensì <strong style="text-decoration: underline;">IL</strong> servizio di
        Cartomanzia Online con le <a href="https://cartomanziaevolution.it/" target="_blank"><strong>migliori cartomanti</strong></a> sul mercato.</p>
    </div>
    <div class="col-lg-12">
      <h3 style="font-weight: 500; font-size: 40px; color: black;">Cosa ci distingue dai soliti centri di
        Cartomanzia tutti Uguali?</h3>
    </div>
    <div class="col-lg-12">
      <p style="color:black; font-size: 18px; font-weight: 400;">Le Cartomanti di Daniel seguono l’antica lettura dei
        Tarocchi rispettando scrupolosamente la tradizione ma in
        aggiunta vengono formate da me <strong>PERSONALMENTE</strong> per integrare alla Cartomanzia Online
        tradizionale, la tecnica
        della Cartomanzia degli Angeli, attraverso un mio personalissimo corso Brevettato e Riconosciuto come altamente
        funzionale dagli esperti di settore: <strong>il metodo Angel Coach™</strong></p>
    </div>
    <div class="col-lg-12">
      <h3 style="font-weight: 500; font-size: 40px; color: black;">Chiama Daniel e le sue Sensitive e
        inizia il tuo percorso di Rinascita in Amore</h3>
    </div>
  </div>
</div>
<hr style="background-color: #33428c; height: 3px; margin-left: 35%; margin-right: 35%;">
<div class="container">
  <div class="row text-center">
    <div class="col-lg-12">
      <h3 style="font-weight: 500; font-size: 40px; color: black;">Perché Daniel Cartomante invece degli altri centri
        di
        Cartomanzia?</h3>
      <p style="font-weight: 500;">Tanti centri di Cartomanzia si affidano ai cosiddetti “centri di risposta”. Ci sono
        delle operatrici che
        rispondono al telefono per molti centri.
        Vengono formate per rispondere a tante clienti di molti centri differenti. I tuo dubbi, i tuoi segreti e le
        tue
        debolezze vengono rivelati a persone senza scrupoli.
        Il nostro centro di Cartomanzia, invece, NON SI AFFIDA a centri di risposta ma ha delle Cartomanti di Fiducia
        selezionate, formate e curate, con le quali potrai confidarti.
      </p>
      <p style="font-weight: 500;">Con loro riuscirai a stringere un rapporto vero</p>

      <h3>Cartomanzia in Amore, Lavoro, Fortuna e Benessere.
        Un numero dedicato per ogni risposta che cerchi</h3>
    </div>
  </div>
</div>
<hr style="background-color: #33428c; height: 3px; margin-left: 35%; margin-right: 35%;">

<div class="container-fluid" style="background-color: #33428c;">
  <div class="title1">
    <div class="row">
      <div class="col-lg-12 text-center" style="padding: 1rem;">
        <h3 style="font-size: 30px; color: white;">Oltre</h3>
        <h3 style="color: white; font-size: 70px;text-shadow: 0px 0px 6px #FFFFFF;">100.000</h3>
        <h3 style="font-size: 30px; color: white;">CONSULTI EFFETTUATI CON SUCCESSO</h3>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid centomila" style="padding: 1rem;">
  <div class="title1">
    <div class="row">
      <div class="col-lg-6 text-center">
        <h3 style="font-size: 38px; color: white; text-decoration: underline;">Segui le mie Dirette su FACEBOOK</h3>

        <p style="font-weight: 400; color: white; font-size: 38px;">Vuoi seguire i miei Consulti in Diretta FB?</p>

        <p style="font-weight: 300; color: white; font-size: 24px;"> Mi trovi dal Vivo tutti i Martedì dalle 21:00</p>

          <a angulartics2On="click" angularticsAction="Chiamata"
          [angularticsCategory]="'Conversione'" href="https://it-it.facebook.com/danielmissoriofficial/" style="text-decoration: none;"
          class="head-899 btn btn-outline bg-custom bg-header-custom"><i class="fa fa-facebook"></i> Segui le mie Dirette su Facebook</a>
      </div>
      <div class="col-lg-6 text-center">
        <img src="/assets/danieldiretta.png" class="img-fluid" alt="daniel diretta">
      </div>
    </div>
  </div>
</div>



<div class="display">
  <div class="container-fluid" style="background-color: #33428c;">
    <div class="row">
      <div class="col-lg-12 text-center">
        <h3 style="font-weight: 500; font-size: 38px; color: white;text-shadow: 0px 0px 2px #ffffff;">TESTIMONIANZE VIP</h3>
      </div>
    </div>
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="d-block m-auto img-fluid" src="/assets/1-s.PNG" alt="First slide">
        </div>
        <div class="carousel-item">
          <img class="d-block m-auto img-fluid" src="/assets/2-s.PNG" alt="Second slide">
        </div>
        <div class="carousel-item">
          <img class="d-block m-auto img-fluid" src="/assets/3-s.PNG" alt="Third slide">
        </div>
        <div class="carousel-item">
          <img class="d-block m-auto img-fluid" src="/assets/4-s.PNG" alt="Fourth slide">
        </div>
        <div class="carousel-item">
          <img class="d-block m-auto img-fluid" src="/assets/5-s.PNG" alt="Fifth slide">
        </div>
        <div class="carousel-item">
          <img class="d-block m-auto img-fluid" src="/assets/6-s.PNG" alt="Sixth slide">
        </div>
        <div class="carousel-item">
          <img class="d-block m-auto img-fluid" src="/assets/7-s.PNG" alt="Seventh slide">
        </div>
        <div class="carousel-item">
          <img class="d-block m-auto img-fluid" src="/assets/8-s.PNG" alt="Eighth slide">
        </div>
        <div class="carousel-item">
          <img class="d-block m-auto img-fluid" src="/assets/9-s.PNG" alt="Ninth slide">
        </div>
        <div class="carousel-item">
          <img class="d-block m-auto img-fluid" src="/assets/10-s.PNG" alt="Tenth slide">
        </div>
        <div class="carousel-item">
          <img class="d-block m-auto img-fluid" src="/assets/11-s.PNG" alt="Eleventh slide">
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>

<script>
  $('.carousel').carousel({
    interval: 1000
  })
</script>

<!--
<div class="container-fluid" style="background-color: #33428c;min-height: 50vh;">
  <div class="row text-center">
    <div class="col-lg-12">
      <h3 style="color: white; font-size: 38px; margin-top: 1rem;">LE MIE TESTIMONIANZE</h3>
    </div>
    <div class="col-lg-4">
      <div class="test-card" style="border-radius: 50%;">
        <img src="/assets/morich.jpg" class="img-fluid testi">
        <p style="color: white;font-size: 22px;">Daniel Missori con Nina Morich</p>
      </div>
    </div>
    <div class="col-lg-4">
      <img src="/assets/pelame.jpg" class="img-fluid testi">
      <p style="color: white;font-size: 22px;">Daniel Missori con Cristiano Malgioglio</p>
    </div>
    <div class="col-lg-4">
      <img src="/assets/marini.jpg" class="img-fluid testi">
      <p style="color: white;font-size: 22px;">Daniel Missori con Valeria Marini e Lorella Maselli</p>
    </div>
    <div class="col-lg-3 col-lg-4">
      <div class="test-card" style="border-radius: 50%;">
        <img src="/assets/daniel-e-amica.jpg" class="img-fluid testi">
        <p style="color: white;font-size: 22px;">Daniel Missori con Lucia Bramieri</p>
      </div>
    </div>
    <div class="col-lg-3 col-lg-4">
      <div class="test-card" style="border-radius: 50%;">
        <img src="/assets/daniel-missori-opinioni.jpg" class="img-fluid testi">
        <p style="color: white;font-size: 22px;">Daniel Missori durante la lettura delle Carte</p>
      </div>
    </div>
    <div class="col-lg-3 col-lg-4">
      <div class="test-card" style="border-radius: 50%;">
        <img src="/assets/daniel-missori-cartomanzia.jpg" class="img-fluid testi">
        <p style="color: white;font-size: 22px;">Daniel Missori durante un Consulto di Cartomanzia in Diretta</p>
      </div>
    </div>
    <div class="col-lg-3 col-lg-4">
      <div class="test-card" style="border-radius: 50%;">
        <img src="/assets/berti.jpg" class="img-fluid testi">
        <p style="color: white;font-size: 22px;">Daniel Missori con Giordano Berti (Esperto di Arte ed Esoterismo)</p>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="test-card" style="border-radius: 50%;">
        <img src="/assets/ken.jpg" class="img-fluid testi">
        <p style="color: white;font-size: 22px;">Daniel Missori con Rodrigo Alves (il Ken Umano)</p>
      </div>
    </div>
    <div class="col-lg-4">
      <img src="/assets/drupi.jpg" class="img-fluid testi">
      <p style="color: white;font-size: 22px;">Daniel Missori con Ivan Cattaneo</p>
    </div>
    <div class="col-lg-6 col-lg-4">
      <img src="/assets/henger.jpg" class="img-fluid testi">
      <p style="color: white;font-size: 22px;">Daniel Missori legge e Carte a Eva Henger</p>
    </div>
    <div class="col-lg-6 col-lg-4">
      <img src="/assets/cipriani.jpg" class="img-fluid testi">
      <p style="color: white;font-size: 22px;">Daniel Missori con Francesca Cipriani</p>
    </div>
  </div>
</div> -->

<div class="container" id="tarocchidegliangeli">
  <div class="row">
    <div class="col-lg-12 text-center" style="margin-bottom: 1rem;">
      <h3 style="font-size: 38px; color: black; font-weight: 450; margin-top: 1rem;">PERCHE' DANIEL?</h3>
      <h3 style="font-size: 32px; color: black; font-weight: 450; margin-top: 1rem;">AFFIDATI AD UN CENTRO DI
        CARTOMANZIA SERIO E FIDATO</h3>
    </div>
    <div class="col-lg-4">
      <img src="/assets/danielmissori.jpg" class="img-fluid">
    </div>
    <div class="col-lg-8">

      <p>
        <br> Cara Amica,</p>
       <p>so che oggi fai fatica a fidarti del prossimo. Se ne sentono di tutti i colori.
        So anche a cosa pensi mentre cerchi un centro di Cartomanzia Online. Sei lì, in preda al panico, lo trovi uno,
        magari ad un buon prezzo, entri, digiti il numero e… e verrai presa inevitabilmente in giro.</p>
      <p>
        La tua debolezza è la forza di molti centri odierni che invece di aiutarti, ti raccontano solo quello che vuoi
        sentirti dire.</p>

      <p>Ti raccontano qualche frottola sul futuro, magari ti impauriscono un po’ per far sì che richiami lo stesso
        centro e tu <strong>RIMANI VITTIMA DI UN GIOCO PERVERSO</strong>.</p>

      <p>Vorrei invitarti solo a riflettere su un punto: leggere il futuro a freddo è impossibile.</p>

      <p>Dunque adesso voglio farti una domanda: in un periodo di oblio e tristezza è meglio conoscere il futuro perché
        speri di lasciarti alle spalle quel senso di malessere o forse è meglio interpretare il passato e correggere il
        presente per poi capire il futuro e riuscire a vivere meglio?
      </p>
    </div>
  </div>
</div>

<div class="container-fluid" style="background-color: #233173;margin-top: 1rem;">
  <div class="container">
    <div class="col-lg-12 text-center">
      <h3 style="font-size: 38px; color: white; font-weight: 500;text-shadow: 0px 0px 2px #ffffff; margin-top: 1rem;">Guarda la video testimonianza di Raffaello Tonon</h3>
    </div>
    <div class="col-lg-12">
      <iframe loading="lazy" frameBorder="0" class="iframestyle" allowfullscreen=""
        src="https://player.vimeo.com/video/360280958?color&amp;autopause=0&amp;loop=0&amp;muted=0&amp;title=1&amp;portrait=1&amp;byline=1#t="
        data-rocket-lazyload="fitvidscompatible"
        data-lazy-src="https://player.vimeo.com/video/360280958?color&amp;autopause=0&amp;loop=0&amp;muted=0&amp;title=1&amp;portrait=1&amp;byline=1#t="
        data-was-processed="true"></iframe>
    </div>
  </div>
</div>

<div class="container"style="background-color:#33428c; padding:1rem; margin-top:1rem;">
  <div class="row">
    <div class="col-lg-3"></div>
    <div class="col-lg-6 text-center">
      <h3 style="color: white;font-weight: 500; font-size: 28px;">Mi trovi dal Vivo tutti i Martedì dalle 21:00</h3>
      <a angulartics2On="click" angularticsAction="Chiamata"
      [angularticsCategory]="'Conversione'" href="https://it-it.facebook.com/danielmissoriofficial/" style="text-decoration: none;"
      class="head-899-fb btn btn-outline bg-custom bg-header-custom"><i class="fa fa-facebook"></i> Segui le mie Dirette su Facebook</a>
    </div>
    <div class="col-lg-3"></div>
  </div>
</div>

<div class="container-fluid shadow-3" id="chisono" style="background-color: #384895;">
  <div class="container">
    <div class="row">
      <div class="col-lg-6" style="margin-top: 1rem; margin-bottom: 1rem;">
        <h3 style="color: white;font-size: 38px; font-weight: 500; margin: 1rem;" class="testochisono-2">CARTOMANTE PROFESSIONISTA</h3>
        <p style="color: white; font-size: 18px; font-weight: 350;" class="testochisono">Sono nato a Roma sotto il segno della Vergine nel 1983. Senza dubbio sono uno dei personaggi di maggior
          spicco nel panorama mediatico italiano di settore. La mia attività di <strong>INTERPRETE DEI TAROCCHI</strong> riscontra
          consensi presso una vastissima fetta di pubblico. Attento conoscitore delle tematiche collegate
          all’<strong>ESOTERISMO</strong>, alla <strong>CARTOMANZIA</strong> e in particolar modo alla <strong>TAROLOGIA</strong>, già da bambino, che io ricordi, mi
          distinguevo in famiglia per mie le innate doti di sensibilità.</p>
      </div>
      <div class="col-lg-6" style="margin-top: 1rem; margin-bottom: 1rem;">
        <img src="/assets/chisono.jpg" alt="daniel cartomanzia" style="border-radius: 50%;" class="img-fluid">
      </div>
    </div>
  </div>
</div>

<div class="float-buttons my-float">
  <a angulartics2On="click" angularticsAction="Chiamata"
  [angularticsCategory]="'Conversione'" href="tel:06402040" target="_blank" style="display: initial;" class="btn btn-2 my-float" role="button"><div style="margin-top: 0.6rem;"><i class="fa fa-info"></i> Servizio Cortesia</div></a>
  <a angulartics2On="click" angularticsAction="Chiamata"
  [angularticsCategory]="'Conversione'" href="tel:899775097" target="_blank" style="display: initial;" class="btn btn-1 my-float" role="button"> <div style="margin-top: 0.3rem;"> <i class="fa fa-phone"></i> Chiama</div></a>
</div>

<div class="footer">
  <div class="container-fluid" style="min-height: 70px;">
    <div class="row">
      <div class="col-lg-12 text-center">
        <h3 style="font-weight: 300; font-size: 18px; color: white;">Copyright © 2020 Daniel e le sue Cartomanti</h3>
      </div>
    </div>
  </div>
</div>
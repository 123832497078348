import { Component, OnInit } from '@angular/core';


export interface CartaGioco {
  description: string;
  index: number;
  carta: string;
  name: string;
}



const carte: CartaGioco[] = [

  {
    index: 1,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque placerat ipsum sit amet consequat rutrum. Maecenas nulla libero, mattis sit amet aliquet eget, facilisis quis odio. Nulla volutpat vitae eros non convallis. Donec eleifend non libero maximus pulvinar. Nullam a velit in odio sagittis sollicitudin nec suscipit orci. Duis et malesuada nulla. Integer quis venenatis nisl, quis finibus eros.',
    carta: '/assets/carte/1.jpg',
    name: 'carta 1',
  },
  {
    index: 2,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque placerat ipsum sit amet consequat rutrum. Maecenas nulla libero, mattis sit amet aliquet eget, facilisis quis odio. Nulla volutpat vitae eros non convallis. Donec eleifend non libero maximus pulvinar. Nullam a velit in odio sagittis sollicitudin nec suscipit orci. Duis et malesuada nulla. Integer quis venenatis nisl, quis finibus eros.',
    carta: '/assets/carte/2.jpg',
    name: 'carta 2',
  },
  {
    index: 3,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque placerat ipsum sit amet consequat rutrum. Maecenas nulla libero, mattis sit amet aliquet eget, facilisis quis odio. Nulla volutpat vitae eros non convallis. Donec eleifend non libero maximus pulvinar. Nullam a velit in odio sagittis sollicitudin nec suscipit orci. Duis et malesuada nulla. Integer quis venenatis nisl, quis finibus eros.',
    carta: '/assets/carte/3.jpg',
    name: 'carta 3',
  },
  {
    index: 4,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque placerat ipsum sit amet consequat rutrum. Maecenas nulla libero, mattis sit amet aliquet eget, facilisis quis odio. Nulla volutpat vitae eros non convallis. Donec eleifend non libero maximus pulvinar. Nullam a velit in odio sagittis sollicitudin nec suscipit orci. Duis et malesuada nulla. Integer quis venenatis nisl, quis finibus eros.',
    carta: '/assets/carte/4.jpg',
    name: 'carta 4',
  },
  {
    index: 5,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque placerat ipsum sit amet consequat rutrum. Maecenas nulla libero, mattis sit amet aliquet eget, facilisis quis odio. Nulla volutpat vitae eros non convallis. Donec eleifend non libero maximus pulvinar. Nullam a velit in odio sagittis sollicitudin nec suscipit orci. Duis et malesuada nulla. Integer quis venenatis nisl, quis finibus eros.',
    carta: '/assets/carte/5.jpg',
    name: 'carta 5',
  },
]


@Component({
  selector: 'app-gioco1',
  templateUrl: './gioco1.component.html',
  styleUrls: ['./gioco1.component.scss']
})
export class Gioco1Component implements OnInit {

  count: number = 0;

  constructor() { }

  carteEstratte: CartaGioco[] = [];

  numeroCarteDaEstrarre = 4;

  clickEsauriti = false;

  ngOnInit(): void {
  }

  estraiCarte() {
    while (this.carteEstratte.length < this.numeroCarteDaEstrarre) {
      const randomNumber = this.getRandomNumberBetween(1, carte.length)
      const cartaGiaEstratta = this.carteEstratte.find(carta => carta.index == randomNumber)
      if (cartaGiaEstratta == undefined) {
        this.carteEstratte.push(carte[randomNumber - 1])
        break
      }
    }
  }
  
  clickCount(): void {
    this.count++
  }

  showMeanings() {
    
  }

  getRandomNumberBetween(min, max) {
    return Math.floor(Math.random() * max) + min;
  }
}
